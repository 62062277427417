$(document).ready(function () {
  // slideshow
  $('.slider-big-detail').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-small-detail',
    nextArrow: '.slider__arrow-box--right',
    prevArrow: '.slider__arrow-box--left',
  })
  $('.slider-small-detail').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-big-detail',
    dots: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
  })
  //slideshow HP
  $('.slideshow__screen').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5700,
    pauseOnHover: false,
  })
})
// resi zobrazeni IC u podnikatelu
$(document).ready(function () {
  var FaChecked = $('.fa-check')

  $('#fa-checked-ico').css('display', 'none')

  $('.fa-ico').click(function () {
    $('#fa-checked-ico').fadeIn()
  })
  $('.fa-unico').click(function () {
    $('#fa-checked-ico').fadeOut()
  })
})

$(document).ready(function () {
  $('#header__menu-button').click(function () {
    $(this).toggleClass('header__menu-button--active'),
      $('#header__menu-mobile').toggleClass('header__menu-mobile--open')
  })
})

var footerSelect = $('.footer__select')

for (var i = 0; i < footerSelect.length; i++) {
  footerSelect[i].onclick = function (event) {
    this.classList.toggle('footer__select--active')
  }
}
